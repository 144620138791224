@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'Abril Fatface';
    font-style: normal;
    font-weight: 400 600 700;
    font-display: optional;
  }

  @font-face {
    font-family: 'Amiri';
    font-style: normal;
    font-weight: 400 600 700;
    font-display: optional;
  }

@layer utilities {
  .nav{
    @apply relative
  }

  .nav::after {
    @apply content-[''] bg-[#03FFC5] -z-10 h-[9px] w-[0%] bottom-[4px]  left-0 absolute duration-300
  }

  .nav:hover::after {
    @apply w-[100%] -z-10
  }

  .active::after {
    @apply content-[''] bg-[#03FFC5] -z-10 h-[9px] w-[100%] bottom-[4px]  left-0
  }

  .tagLine {
    position: relative;
  }

  .tagLine::after {
    content: '';
    background-color: #03FFC5;
    height: 40px;
    width: 0%;
    bottom: 0px;
    left: 0;
    position: absolute;
    z-index: -1;
    animation: tagLineAnimation 5s linear infinite;
  }

  @media only screen and (max-width: 820px) {
    .tagLine::after {
    height: 30px;
  }
  }

  @media only screen and (max-width: 600px) {
    .tagLine::after {
    height: 20px;
  }
  }

  @keyframes tagLineAnimation {
    0% {
      width: 0%;
    }
    60% {
    width: 100%;
    opacity: 1;
  }
  100% {
    width: 100%;
    opacity: 0;
  }
  }
  
}